@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@300&display=swap);
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  margin: 0;
  font-family: 'Roboto Slab' !important;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
pre{
  font-family:"Roboto" !important;
  font-size: 14px !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a{
  color: inherit !important;
}
a.d-inline-flex.active{
  color: #007bff !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.login__header{background:#0f9d58;padding:8px}.login-h1{color:#63b9d9;text-align:center}

nav{display:flex;background:#0f9d58;padding:10px;justify-content:space-between;align-items:center;margin-bottom:15px}nav .MuiOutlinedInput-input{padding:0;padding:12px}nav .MuiButton-root{min-width:32px !important}nav .ham{display:block;width:32px;height:32px}nav .logo{margin-left:24px}nav span p{color:brown;font-size:16px;line-height:1.2}nav .seach-bar{display:flex;height:40px;margin-right:15px}

.profile-container{position:relative;padding:20px 0px 20px 50px;border:1px solid #7ac4df;margin-top:15px}.icon-box{position:absolute;right:10px;top:10px;color:#7ac4df}.marb-1{margin-bottom:15px}

.delivery-boi__header{display:flex;align-items:center;justify-content:space-between}.delivery-boi__seach-bar{display:flex;height:30px}.delivery-boi__seach{padding-left:8px;padding-right:8px;border:none;border:1px solid #e6e6e6}.delivery-boi__seach:focus{outline:none}.delivery-boi__seach::-webkit-input-placeholder{color:#cccccc}.delivery-boi__seach:-ms-input-placeholder{color:#cccccc}.delivery-boi__seach::placeholder{color:#cccccc}.delivery-boi__avatar{width:150px;height:150p;border-radius:50%}.delivery-boi__info h3{font-weight:500}.delivery-boi__actions button{border-radius:0}.delivery-boi__actions button:not(:last-child){margin-right:10px}input::-webkit-outer-spin-button,input::-webkit-inner-spin-button{-webkit-appearance:none;margin:0}input[type=number]{-moz-appearance:textfield}

