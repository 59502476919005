.delivery-boi {

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__seach-bar {
        display: flex;
        height: 30px;
        // margin-right: 15px;
    }

    &__seach {
        padding-left: 8px;
        padding-right: 8px;
        border: none;
        // border-color: #eee;
        border: 1px solid #e6e6e6;

        &:focus {
            outline: none;
        }

        &::placeholder {
            color: #cccccc;
        }
    } 
    
    &__avatar {
        width: 150px;
        height: 150p;
        border-radius: 50%;
    }

    &__info {
        h3 {
            font-weight: 500;
        }
    }

    &__actions {
        button {
            border-radius: 0;
        }

        button:not(:last-child) {
            margin-right: 10px;
        }
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}